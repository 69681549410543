.container {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-flow: row nowrap;
  background-color: #424242;
  background-image: radial-gradient(
    circle at 0 0,
    rgba(255, 255, 255, 0.25),
    transparent
  );
  color: #fff;
  align-content: flex-start;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.copyContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.5));
  padding: 14rem 2rem 0rem;

  a {
    color: #fff;
  }
}

.author {
  position: relative;
  display: flex;
  flex: 0 1 auto;
  width: 100%;
  align-items: flex-end;
  font-size: 2.4rem;
  font-weight: 100;
  margin: 0 0 0 2rem;
  padding: 0;
  font-family: '\'Adriane Text W01\', Georgia, serif';
  font-weight: normal;

  small {
    text-shadow: none;
    text-transform: lowercase;
    color: #ddd;
    font-size: 62.5%;
    margin-right: 1rem;
    padding-bottom: 0.5rem;
  }
}

.name {
  display: inline-block;
  position: relative;
}

.sponsor {
  font-size: 1.2rem;
  margin: 0 0 0 2rem;
  padding: 0;
  font-family: '\'Adriane Text W01\', Georgia, serif';
  font-weight: normal;
  color: rgba(255, 255, 255, .4);

  a {
    font-size: 1.4rem;
    font-weight: 600;
    color: rgba(255, 255, 255, .95);
    text-decoration: none;

    &[href] {
      cursor: pointer;
      text-decoration: underline;
      text-decoration-color: #d7d700;
    }
  }
}

.headshot {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
}

.scrollableArea {
  flex: 1 1 100%;
  margin: 2rem 0 0;
  padding: 0 0 0 2rem;

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.biography {
  flex: 0 1 100%;
  font-size: 1.414rem;
  line-height: 1.6;
  font-weight: 100;
  color: #ddd;
  font-family: 'Avenir W01', Helvetica, sans-serif;
  color: #aaa;
}
