.container {
  > div {
    background-image: url(../../assets/The_Moth_Logo.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 6.907rem 5.5rem;
    width: 6.907rem;
    height: 5.5rem;

    @media only screen and (max-width: 768px) {
      background-size: 5.6rem 4.5rem;
      width: 5.6rem;
      height: 4.5rem;
    }

    @media only screen and (min-width: 768px) {
      margin-top: 1rem;
    }
  }
}
