.container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-radius: 4px;
  background-color: #221f1f;
  flex: 1 1 100%;
  position: relative;
}

.input,
.icon {
  color: #efefef;
}

.icon {
  margin: auto 1.5rem auto 1rem;
  flex: 0 0 auto;
  padding: 0 0.5rem;
  line-height: 3.4rem;
  font-size: 1.414rem;
}

.input {
  background-color: transparent;
  padding: 0;
  margin: 0;
  flex: 1 1 auto;
  border: none;
  height: 3.4rem;
  font-family: 'Avenir W01', Helvetica, sans-serif;
  font-size: 1.414rem;

  &:focus,
  &:active {
    outline: none;
  }
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: 'Avenir W01', Helvetica, sans-serif;
  font-size: 1.414rem;
  max-height: 20rem;
  overflow-y: auto;
  flex: 1 1 100%;
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #222;
}

.listItem {
  padding: 1rem 0 1rem 5rem;
  flex: 0 0 auto;
  cursor: pointer;
  transition: all 175ms ease-in-out;

  &:nth-child(2n+1) {
    background-color: rgba(255, 255, 255, 0.05);
  }

  &:hover {
    background-color: #d7d600;
    color: #222;
  }

  &.active {
    color: white;
    background-color: hsla(225, 50%, 50%, 1);
  }
}

.highlighted {
  color: #d7d600;
  position: relative;
  border-bottom: 1px solid #ddd;
}

.label {
  height: 3.4rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;
}

.labelText {
  font-size: 1.414rem;
  font-family: 'Avenir W01', Helvetica, sans-serif;
}

.labelClose {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 3.4rem;
  width: 3.4rem;
  line-height: 3.4rem !important;
  text-align: center;
}
