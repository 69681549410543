.container {
  position: relative;
  width: 100%;
  height: 1rem;
  background-color: #444;
  background-color: darken(#d7d600, 60%);
  transition: 175ms ease-in-out;

  &:hover,
  &.scrubbing {
    height: 2.5rem;
  }
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

.hitbox {
  cursor: pointer;
  z-index: 1000;
  position: absolute;
  top: -1rem;
  right: 0;
  bottom: -1rem;
  left: 0;
}

.progress {
  position: relative;
  width: 70%;
  height: 100%;
  background-color: #d7d600;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  background-size: 50px 50px;
  transition: background-color 75ms ease-in-out;

  &.playing {
    animation: move 2s linear infinite;
  }

  .container:hover &,
  .container.scrubbing & {
    background-color: lighten(#d7d600, 5%);
  }


  &::after {
    content: ' ';
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 2px;
    background-color: #fff;
    height: 150%;
  }
}
