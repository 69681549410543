.container {
  border-radius: 3px;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  background-color: #fff;
  box-shadow: 2px 0 5px 3px rgba(0, 0, 0, 0.25);
  align-content: flex-start;
  height: 100vh;
  width: 100%;

  &.loading {
    align-items: center;
    justify-content: center;
  }
}

.logo {
  margin-bottom: 4rem;
}

.logo p {
  text-align: center;
  width: 100%;
  padding: 0.5rem;
}

.logo > div {
  filter: invert(1);
  margin: auto;
  background-size: 10.047rem 8rem !important;
  width: 10.047rem !important;
  height: 8rem !important;
}
