.listItem {
  transform-style: preserve-3d;
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  font-size: 1.4rem;
  color: #555;
  padding: 1rem 1rem 1rem 7rem;
  transform-origin: 0 0;

  &:nth-child(2n) {
    background-color: #fbfbfb;
  }

  & + .listItem {
    border-top: 1px solid #f7f7f7;
  }

  small {
    color: #888;
  }

  &:hover {
    background-color: #d7d60040;
  }

  &.active {
    background-color: #d7d600;
    color: #1f1c1c;

    small {
      color: #1f1c1c;
    }
  }
}

.leader {
  flex: 0 0 2.8rem;
  height: 2.8rem;
  margin-right: 1rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.playing {
    background-color: #2f2f2f;
    border: 2px solid #000;
    color: #fff;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.dataItem {
  color: #212121;
  font-size: 1.414rem;
  align-items: center;
  align-content: center;
  display: flex;

  &:hover {
    text-decoration: none;
    cursor: default;
  }
}

.title {
  composes: dataItem;
  flex: 0 0 30%;
  cursor: pointer;

  &.active {
    font-weight: 800;
  }

  &:hover {
    text-decoration: underline;
  }

  @media only screen and (max-width: 768px) {
    flex: 0 0 100%;
  }
}

.storyteller {
  composes: dataItem;
  flex: 0 0 25%;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 1rem;
    flex: 1 1 50%;
    pointer-events: none;
  }
}

.headshot {
  height: 4rem;
  width: 4rem;
  margin-right: 1rem;
  border-radius: 4px;
  vertical-align: middle;

  @media screen and (max-width: 768px ){
    width: 2.5rem;
    height: 2.5rem;
  }
}

.date {
  composes: dataItem;
  flex: 0 0 25%;
  margin: 0 1rem;
  font-size: 1.2rem;
  color: #888;

  @media only screen and (max-width: 1024px) {
    display: none;
  }
}

.duration {
  composes: dataItem;
  justify-content: flex-end;
  flex: 1 1 auto;
  margin-left: auto;
  font-size: 1.2rem;
  text-align: right;
  padding-right: 1rem;
  color: #888;
}

.favorite {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border: none;
  padding: 0 2rem;
  background-color: rgba(255, 255, 255, 0.5);
  color: #727272;
  font-size: 2rem;
  transition: all 175ms ease-in-out;
  border-radius: 0 10rem 10rem 0;

  &:focus {
    outline: none;
  }
}
