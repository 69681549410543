.container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  background-color: #0f0c0c;
  color: #efefef;
  z-index: 100;
  padding: .5rem 1rem;
  background-image: linear-gradient(
    45deg,
    transparent 10px, run
    transparent 20px,
    white 20px,
    white 30px,
    transparent 30px,
    transparent 40px,
    white 40px,
    white 50px,
  );
}

.containerSponsor {
  @media only screen and (max-width: 768px),
         only screen and (max-device-width: 768px) {
    padding: 0;
  }
}

.heading {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  padding: .5rem 1rem;
  font-family: '\'Adriane Text W01\', Georgia, serif';
  font-weight: 100;
  font-size: 1.6rem;

  @media only screen and (max-width: 768px),
         only screen and (max-device-width: 768px) {
    display: none;
  }
}

.filterGroups {
  flex: 1 1 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
}

.filterGroup {
  flex: 0 1 auto;
  display: block;
  position: relative;
  align-items: end;
  align-content: center;
  padding: .5rem 1rem;

  @media only screen and (max-width: 768px),
         only screen and (max-device-width: 768px) {
    flex: 1 1 auto;

    &.search {
      flex-basis: 65%;
    }
  }

  @media only screen and (max-width: 480px),
         only screen and (max-device-width: 480px) {
    flex: 1 1 100%;
    padding:0 1rem 1rem;

    &:first-child {
      padding-top: 1rem;
    }
  }
}

.prependedInput {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex: 1 1 100%;
  background-color: #221f1f;

  input,
  .input,
  div,
  span {
    flex: 1 1 100%;
    color: #fefefe;
    font-size: 1.414rem;
    background-color: #221f1f;
    font-family: 'Avenir W01', Helvetica, sans-serif;
    border: none;
    outline: none;
    color: #efefef;
  }

  .search & {
    border-radius: 25px;
    overflow: hidden;
  }
}

.inputLabel {
  width: 4rem;
  text-align: center;
  font-size: 1.414rem;
}

.search {
  input {
    appearance: none;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0 1rem;
    margin: 0;
    height: 34px;
    line-height: 34px;

    &:focus,
    &:active {
      outline: none;
    }
  }
}
