*,
*::before,
*::after {
  box-sizing: border-box;
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

input,
input::before,
input::after {
  user-select: initial;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-rendering: optimizeLegibility;
}

h1,
h2,
h3,
h4,
h5,
h6,
li,
p {
  cursor: default;
  text-rendering: optimizeLegibility;
}

body {
  margin: 0;
}

html {
  font-size: 62.5%;
  min-width: 320px;
  -webkit-text-size-adjust: 100%;
}

.container {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.body {
  width: 100%;
  height: 100vh;
}

.smallVerticalViewport {
  overflow-y: auto;

  .body {
    height: auto;
    min-height: 100%;
  }

  .lists {
    flex: 0 1 auto;
  }

  .listsWrapper,
  .trackList,
  .trackList > * {
    flex: 1 1 auto;
  }

  .sponsor > *:first-child > *:last-child {
    margin-top: 0;
  }
}

.trackInfo {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  flex: 0 0 auto;
}

.currentTrack {
  flex: 1 1 55%;
  z-index: 12;

  @media only screen and (max-width: 768px),
         only screen and (max-device-width: 768px) {
    flex: 1 1 100%;
  }
}

.storyteller {
  flex: 1 1 45%;
}

.scrubber {
  flex: 0 0 auto;
  z-index: 100;
}

.lists,
.listsWrapper {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-wrap: nowrap;
}

.lists {
  flex: 0 1 100%;

  @media only screen and (max-width: 768px),
         only screen and (max-device-width: 768px) {
    flex-direction: column;
  }
}

.sponsor {
  flex: 0 0 auto;
  z-index: 100;

  @media only screen and (max-width: 768px),
         only screen and (max-device-width: 768px) {
    flex-direction: column;
    max-width: 100%;
  }

  @media only screen and (min-width: 768px) {
    > * {
      flex-direction: column;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    + .listsWrapper .discovery > * {
      flex: 1 1 100%;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      > * {
        flex: 1 1 auto;
      }
    }
  }
}

.listsWrapper {
  flex: 1 1 100%;
  flex-direction: column;
  z-index: 0;

  @media only screen and (min-width: 768px) {
    flex: 1 1 auto;
    min-width: calc(100vw - 26rem);
  }
}

.discovery {
  flex: 0 0 auto;
}

.trackList {
  flex: 1 1 auto;
  @media only screen and (max-width: 768px),
         only screen and (max-device-width: 768px) {
    flex: 1 1 100%;
  }
}
