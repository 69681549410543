.container {
  display: flex;
  max-height: 100%;

  @media (min-width: 768px) {
    max-width: 26rem;
    transition: max-width 300ms;
  }
}

.visorIsOpen {
  @media (min-width: 768px) {
    max-width: calc(40vw + 26rem);
  }
}

.pane {
  background-color: #1e1e1e;
  color: #5f5f5f;
  display: flex;
  padding: 2rem;
  overflow-y: auto;
  font-family: '\'Adriane Text W01\', Georgia, serif';
  z-index: 2;

  @media only screen and (max-width: 480px),
         only screen and (max-device-width: 480px) {
    flex-wrap: wrap;
  }

  @media (max-width: 768px) {
    align-items: center;
    flex: none;
    display: flex !important;
  }

  @media (min-width: 768px) {
    padding: 4rem 4rem 0;
    width: 26rem;
    min-width: 26rem;

    > *:last-child {
      margin-top: auto;
    }
  }

  > * {
    &:empty {
      display: none;
    }

    @media (max-width: 768px) {
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }

    @media (min-width: 768px) {
      &:not(:last-child):not(.logo):after {
        content: '';
        width: 2.5rem;
        height: .1rem;
        background-color: #313131;
        margin: 2.5rem auto;
        display: block;
      }

      &:last-child > *:last-child {
        margin-bottom: 4rem;
      }
    }
  }

  span {
    font-weight: normal;
  }
}

.logo {
  width: 4.2rem;
  height: 4.2rem;
  display: flex;
  border-radius: .5rem;
  flex: none;

  @media (min-width: 768px) {
    min-width: 11.6rem;
    min-height: 11.6rem;
    margin: 0 auto 2rem;
  }

  img {
    margin: auto;
    display: block;
    width: 100%;
    height: auto;
    max-width: 90%;
  }
}

.heading {
  font-family: '\'Adriane Text W01\', Georgia, serif';

  @media (max-width: 480px) {
    flex: 1 1 0%;
  }

  h2,
  h2 span,
  a {
    color: white;
    font-weight: 600;
  }

  h2,
  h3 {
    margin: 0;
    text-align: left;

    @media (min-width: 768px) {
      text-align: center;
    }
  }

  h2:not(:empty) {
    font-size: 1.6rem;
    margin-bottom: .25rem;
  }

  h3 {
    font-size: 1.2rem;
    a {
      text-decoration-color: #d7d700;
      font-size: 1.4rem;
    }
  }

  @media only screen and (min-width: 768px),
         only screen and (min-device-width: 768px) {
    text-align: center;
  }
}

.summary,
.tracks {
  font-size: 1.2rem;
  text-align: center;

  p {
    display: block;
    margin: 0 auto;
  }

  @media only screen and (max-width: 768px),
         only screen and (max-device-width: 768px) {
    display: none;
  }
}

.visorCta {
  display: inline-flex;
  flex: 1 1 auto;

  @media only screen and (max-width: 480px) {
    flex: 1 0 100%;
    min-width: 100%;
    padding: 1rem 0 0 5.7rem;
  }

  @media only screen and (min-width: 768px) {
    flex: none;
  }

  button {
    background-color: #d7d700;
    color: #1e1e1e;
    border: none;
    font-family: 'Avenir W01', Helvetica, sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1;
    padding: 1.2rem 1.5rem;
    border-radius: .4rem;
    outline-color: transparent;
    margin-left: auto;

    @media only screen and (max-width: 768px) {
      padding: 1.2rem 1.5rem;
    }

    @media only screen and (max-width: 480px) {
      margin-left: 0;
    }

    @media only screen and (min-width: 768px) {
      width: 100%;
    }
  }
}

.visor {
  font-family: 'Avenir W01', Helvetica, sans-serif;
  display: flex;
  height: 0;
  overflow: hidden;

  @media only screen and (min-width: 768px) {
    height: 100%;
    width: 0;
    transition: 300ms;
    transition-delay: 300ms;
  }
}

.visorOpen {
  height: 100%;

  @media only screen and (min-width: 768px) {
    width: 40vw;
    transition-delay: 0ms;
  }

  .visorContent > div {
    opacity: 1;

    @media only screen and (min-width: 768px) {
      transition: opacity 300ms 150ms;
    }
  }

  .visorClose {
    display: block;
  }

  .visorOverlayBg {
    @media only screen and (min-width: 768px) {
      right: 0;
      background-color: rgba(0,0,0,.75);
    }
  }
}

.visorContent {
  padding: 3.5rem 3rem 0;
  overflow: auto;
  background-color: #fff;
  display: flex;
  flex-flow: column nowrap;
  z-index: 2;
  position: relative;
  min-height: 100%;

  > div,
  h3,
  p {
    display: block;
    margin: 0;
  }

  > div {
    opacity: 0;
    transition: opacity 150ms;
    padding-bottom: 3.5rem;
  }

  h3 {
    font-size: 1.8rem;
    font-weight: 600;
    color: #5d5d5d;
    margin-bottom: 2rem;
    line-height: 1;
  }

  p {
    color: #727272;
    font-size: 1.6rem;
    line-height: 1.4;
  }
}

.visorClose {
  display: none;
  position: absolute;
  top: 2rem;
  right: 2rem;
  height: 1.75rem;
  width: 1.75rem;
  background: transparent;
  outline: transparent;
  border: none;
  display: block;
  padding: 0;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: .2rem;
    background-color: #1e1e1e;
    position: absolute;
    top: 45%;
    left: 0;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.visorOverlayBg {
  background-color: rgba(0,0,0,0);
  transition: background 300ms;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    left: 26rem;
  }
}
