.container {
  display: flex;
  flex: 0 1 32rem;
  margin: 0 auto;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
}

.button {
  flex: 0 0 6rem;
  height: 6rem;
  border: none;
  padding: 1rem 0;
  font-size: 1.414rem;
  background-color: #ffffff;
  border-radius: 50%;
  color: #222;
  background-image: linear-gradient(
    to bottom right,
    transparent 50%,
    #f0f0f0 50%,
    darken(#f0f0f0, 20%)
  ),
  linear-gradient(
    to top left,
    transparent 50%,
    darken(#d7d600, 10%) 50%,
    darken(#d7d600, 0)
  );
  background-repeat: no-repeat;
  background-position:
    center center,
    -100px -100px;
  box-shadow: 0 1px 12px -5px rgba(0, 0, 0, 0.75);
  transition:
    background-color 0ms 175ms ease-in,
    color 0ms 125ms ease-out,
    background-position 75ms 175ms ease-out,
    text-shadow 25ms 125ms ease-out,
    font-size 50ms 125ms ease-in-out,
    transform 0ms 150ms ease-in-out;

  &.large {
    flex: 0 0 8rem;
    height: 8rem;
    font-size: 2.887rem;

    @media only screen and (max-width: 480px) {
      font-size: 1.414rem;
      height: 6rem;
      flex: 0 0 6rem;
    }
  }

  &:focus {
    outline: none;
  }

  &:not([disabled]):hover {
    background-color: #d7d600;
    color: #444;
    background-position:
      100px 100px,
      center center;
    transform: scale(1.05);
    text-shadow: 0px 0px 5px rgba(236, 236, 0, 1);
  }

  &.active {
    background-color: #d7d600;
    color: #444;
    background-position:
      100px 100px,
      center center;
    transform: scale(1.05);
    text-shadow: 0px 0px 5px rgba(236, 236, 0, 1);
  }

  &:active {
    transform: scale(1.0);
    background-color: darken(#d7d600, 7%);
  }

  &[disabled] {
    opacity: 0.25;
    transform: scale(0.8);
  }

  @media only screen and (max-width: 480px) {
    font-size: 1.414rem;
    height: 5rem;
    flex: 0 0 5rem;
  }
}
