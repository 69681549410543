.container {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 100%;
  font-family: 'Avenir W01', Helvetica, sans-serif;
  transform-style: preserve-3d;
  perspective: 800;
  overflow: hidden;
}

.scrollableContainer {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex: 1 1 100%;
  width: 100%;
  flex-flow: column nowrap;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  perspective: 800;
}

.seperator {
  margin: 1rem 15rem;
  height: 1px;
  background-color: #ccc;
  flex: 1 1 100%;
}

.listWrapper {
  position: relative;
}

.subHeader {
  flex: 0 0 auto;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0;
  padding: 0.5rem 2rem;
  background-color: #313131;
  color: #727272;
}

.loader {
  position: absolute;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 2rem 5rem;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0) scale(0.75);
  border-radius: 7px;
}

.message {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  span {
    color: #999;
    font-size: 4rem;
    @media only screen and (max-width: 768px) {
      font-size: 2rem;
    }
  }
}

.trackListHeader {
  background-color: #444;
  list-style: none;
  margin: 0;
  padding: 1rem;
  display: flex;
  flex-flow: row nowrap;
  font-size: 1.414rem;
  padding: 0.5rem 0;

  @media screen and (max-width: 768px ){
    display: none;
  }
}

.trackListHeaderItem {
  color: #eee;
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    text-decoration: none;
    cursor: default;
  }
}

.trackListHeaderTitle {
  composes: trackListHeaderItem;
  flex: 0 0 30%;
  margin-left: 8rem;
}

.trackListHeaderStoryteller {
  composes: trackListHeaderItem;
  flex: 0 0 25%;
}

.trackListHeaderAirDate {
  composes: trackListHeaderItem;
  flex: 0 0 25%;

  @media only screen and (max-width: 1024px) {
    display: none;
  }
}

.trackListHeaderDuration {
  composes: trackListHeaderItem;
  flex: 1 1 auto;
  margin-left: auto;
  align-self: center;
  text-align: right;
  padding-right: 2rem;
}
