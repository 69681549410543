.container {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: flex-start;
  padding: 0.7rem 0 2.8rem;
  background-color: #0f0c0c;
  color: #fff;
  height: 34rem;

  @media only screen and (max-width: 768px),
         only screen and (max-device-width: 768px) {

     height: auto;
     padding: 1rem 0;
   }
}

.logo {
  margin-left: 3.2rem;
  svg {
    width: 160px;
    height: 70px;
  }
}

.titleBlock {
  flex: 1 1 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  align-content: center;
  padding: 1rem 0;
  overflow: hidden;
}

.now {
  font-size: 1.3em;
  font-family: 'Avenir W01', Helvetica, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}

.title {
  font-family: '\'Adriane Text W01\', Georgia, serif';
  font-weight: 400;
  padding-top: 1rem;
  text-align: center;
}

.accent {
  width: 14px;
  height: 20px;
  margin: 0 0.5rem;
  path {
    stroke: #d7d600;
    stroke-width: 1px;
  }
}

@media only screen and (max-width: 768px),
       only screen and (max-device-width: 768px) {
  .now {
    display: none;
  }
}

.title {
  font-size: 3.4rem;
  font-weight: 400;
  margin: 0;

  @media only screen and (max-width: 768px),
         only screen and (max-device-width: 768px) {

     font-size: 2.4rem;
   }
}

.time {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  margin: 1rem 0;
  padding: 0;
  font-family: 'Avenir W01', Helvetica, sans-serif;
}

.elapsed,
.duration {
  font-size: 1.414rem;
  font-weight: 300;
  // padding: 0.5rem 0.75rem;
}

.elapsed {
  color: #eee;
  border-bottom: 1px dashed #eee;
  cursor: ew-resize;

  @media only screen and (max-width: 768px) {
    border-bottom: none;
    cursor: default;
  }
}

.duration {
  color: #555;
}
